<template>
  <div class="pop-up-send-models-help-container">
    <div class="pop-up-send-models-help">
      <p>¿Pedir ayuda con modelos?</p>
      <div class="pop-up-send-models-help-buttons-confirm">
        <button class="pop-up-send-models-help-cancel" @click="toggleState('popupSendModels')">Cancelar</button>
        <button class="pop-up-send-models-help-send" @click="sendHelp">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("connectedUsers", {
      modelsAssigned: "modelsAssigned",
    }),
    chatGroup() {
      return this.$store.state.chat.chatGroup;
    },
  },
  methods: {
    ...mapActions("chat", {
      sendMessages: "sendMessages",
      toggleState: "toggleState",
    }),
    async sendHelp() {
      this.sendMessages({
        message: "Ayuda mis modelos desatentidas",
        toUsers: this.chatGroup._id,
        group: this.chatGroup._id,
        currentModels: this.modelsAssigned,
      });
      this.toggleState("popupSendModels");
    },
  },
};
</script>

<style lang="scss">
.pop-up-send-models-help-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  .pop-up-send-models-help {
    background-color: white;
    width: 300px;
    border-radius: 11px;
    text-align: center;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
    p {
      padding: 10px 0;
      color: #e11f1f;
      font-weight: 600;
      border-bottom: 1px solid #cacaca;
    }
    .pop-up-send-models-help-buttons-confirm {
      padding: 20px 0;
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
      button {
        width: 100px;
        padding: 7px 15px;
        margin-right: 15px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 600;
        background-color: transparent;
        transition: all 0.5s ease-in-out;
      }
      .pop-up-send-models-help-cancel {
        border: 1px solid #bd0909;
        color: #bd0909;
      }
      .pop-up-send-models-help-cancel:hover {
        background-color: #bd0909;
        color: #fff;
      }
      .pop-up-send-models-help-send {
        background-color: #bd0909;
        color: #fff;
      }
      .pop-up-send-models-help-send:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
